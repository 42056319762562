import { AuthConfig, L1Config, L1L3AuthService } from 'ngx-auth-util-lib';
import { environment } from 'src/environments/local/environment';

import { Component, OnInit } from '@angular/core';

import { MatomoConfigService } from './services/matomo-config.service';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter, map, mergeMap } from 'rxjs/operators';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  version: string;
  environment: string;
  constructor(
    private l1l3AuthService: L1L3AuthService,
    private matomoConfigService: MatomoConfigService,
    private titleService: Title,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.version = environment.appVersion;
    this.environment = environment.envName;

    const l1Config = new L1Config(
      'L1_STORAGE_KEY',
      environment.auth_L1.scope,
      environment.auth_L1.client_id,
      environment.auth_L1.endpoint.host +
        environment.auth_L1.endpoint.paths.prefix,
      {
        auth: environment.auth_L1.endpoint.paths.login,
        token: environment.auth_L1.endpoint.paths.token,
        publicKey: environment.auth_L1.endpoint.paths.certs,
        logout: environment.auth_L1.endpoint.paths.logout,
        account: environment.auth_L1.endpoint.paths.account,
      },
      'ID_TOKEN_HINT_STORAGE_KEY',
      environment.redirect.host + environment.redirect.route,
      environment.redirect.host
    );
    const l3Config = new AuthConfig(
      'L3_STORAGE_KEY',
      environment.backend.scope,
      environment.backend.client_id,
      environment.backend.endpoint.host +
        environment.backend.endpoint.paths.prefix,
      {
        auth: environment.backend.endpoint.paths.token,
        logout: environment.backend.endpoint.paths.logout,
      }
    );
    this.l1l3AuthService.initAuth(l1Config, l3Config, [
      environment.backend.endpoint.host,
    ]);
    this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        map(() => this.activatedRoute),
        map(route => {
          while (route.firstChild) route = route.firstChild;
          return route;
        }),
        mergeMap(route => route.data)
      )
      .subscribe(data => {
        this.titleService.setTitle(data['title']);
      });
  }
}
